.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 82%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  overflow-x: auto;
}

.modal-content {
  position: relative;
  padding: 1rem;
  background: white;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  height: fit-content;;
  width: 80vw;
}

/* Media query for desktop screens */
@media (min-width: 768px) {
  /* Adjust the min-width as needed for your design */
  .modal-content {
    position: relative;
    padding: 1rem;
    background: white;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    height: auto;
    width: 80vw;
  }
}



.modal-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  /* Adjust this value to align it vertically */
  right: 10px;
  /* Adjust this value to align it horizontally */
  padding: 0;
  /* Adjust padding as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s;

  /* Optional: add a hover effect */
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.modal-close-btn svg {
  fill: black;
  /* or any color you prefer */
  width: 24px;
  /* Adjust width as needed */
  height: 24px;
  /* Adjust height as needed */
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  /* Card background color */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.card__mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  /* Card background color */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  flex-direction: column;
}

/* Mobile first  */
.card-column {
  flex: 1;
  /* Equal width for all columns */
  text-align: center;
  /* Center content */
  width: 100%;
}

/* Media query for desktop screens */
@media (min-width: 768px) {

  /* Adjust the min-width as needed for your design */
  .card-column {
    width: auto;
  }
}

.card-image {
  max-width: 100%;
  /* Ensure the image fits in the column */
  width: 50%;
  height: auto;
  /* Maintain aspect ratio */
}

.card-title {
  font-weight: bold;
  color: black;
  text-align: left;
}

.card-price,
.card-title {
  text-align: left;
}

.card-limited {
  color: red;
}

.payment-card {
  justify-content: center;
  /* Center the content of the payment card */
}

.payment-button {
  padding: 10px 20px;
  background-color: #6cca70;
  /* Example color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.payment-button:hover {
  background-color: #45a049;
}