*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 16px;
	--color-text: rgba(0, 0, 0, 0.8);
	--color-bg: #181818;
	--color-link: #ebf47d;
	--color-link-hover: #000;
	--color-title: rgba(0, 0, 0, 0.85);
	--color-switch-bg: #f1ff85;
	--color-switch: #000;
	--color-switch-active: #c0c867;
}

html,
body {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	margin: 0;
}

body {
	font-weight: 500;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: "owners-wide", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	--grid-item-width: 50vw;
	--grid-item-height: 100vh;
}


@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 1);
	}

}

.desktop-only {

	a {
		text-decoration: none;
		color: var(--color-link);
		outline: none;
	}

	a:hover {
		color: var(--color-link-hover);
		outline: none;
	}

	/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
	a:focus {

		/* Provide a fallback style for browsers
	 that don't support :focus-visible */
		outline: none;
		background: lightgrey;
	}

	a:focus:not(:focus-visible) {

		/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
		background: transparent;
	}

	a:focus-visible {

		/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
		outline: 2px solid red;
		background: transparent;
	}

	.unbutton {
		background: none;
		border: 0;
		padding: 0;
		margin: 0;
		font: inherit;
	}

	.unbutton:focus {
		outline: none;
	}

	.oh {
		position: relative;
		overflow: hidden;
	}

	.frame {
		padding: 1rem;
		display: grid;
		grid-template-columns: 100%;
		justify-items: flex-start;
		grid-template-areas: 'heading' 'sponsor' 'title' 'prev';
		grid-template-rows: 3.5rem auto;
		justify-content: flex-start;
		align-items: flex-start;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		grid-gap: 0.5rem;
		z-index: 1000;
		text-transform: uppercase;
		pointer-events: none;
		color: white;
		mix-blend-mode: difference;
	}

	.frame a,
	.frame button {
		pointer-events: auto;
	}

	.frame a:not(.frame__title-back) {
		white-space: nowrap;
		overflow: hidden;
		position: relative;
		display: inline-block;
	}

	.frame a:not(.frame__title-back)::before {
		content: '';
		height: 1px;
		width: 100%;
		background: currentColor;
		position: absolute;
		top: 90%;
		transition: transform 0.3s;
		transform-origin: 0% 50%;
	}

	.frame a:not(.frame__title-back):hover::before {
		transform: scaleX(0);
		transform-origin: 100% 50%;
	}

	.frame__heading {
		grid-area: heading;
		align-self: center;
		text-transform: capitalize;
		font-family: antonia-variable, sans-serif;
		font-size: 2rem;
		font-variation-settings: "opsz" 48, "wght" 800;
		mix-blend-mode: difference;
	}

	body #cdawrap {
		align-self: center;
		display: flex;
		gap: 0.5rem;
	}

	.frame__title {
		grid-area: title;
		display: flex;
	}

	.frame__title-main {
		font-size: inherit;
		margin: 0;
		font-weight: inherit;
	}

	.frame__title-back {
		position: relative;
		display: flex;
		align-items: center;
	}

	.frame__title-back span {
		display: none;
	}

	.frame__title-back svg {
		fill: currentColor;
	}

	.frame__prev {
		grid-area: prev;
	}

	.buy-picture {
		position: fixed;
		right: 2rem;
		top: 5rem;
		scale: 0;
		z-index: 99;
	}

	.buy-picture-button {
		display: inline-block;
		/* Allows padding and width/height to be set */
		padding: 10px 20px;
		/* Button padding */
		background-color: #007bff;
		/* Background color */
		color: white;
		/* Text color */
		border: none;
		/* No border */
		border-radius: 5px;
		/* Rounded corners */
		cursor: pointer;
		/* Change mouse cursor on hover */
		text-align: center;
		/* Center text */
		transition: background-color 0.3s, color 0.3s;
		/* Smooth transition for hover effect */
		text-decoration: none;
		/* Remove underline if used within an <a> tag */
	}

	.buy-picture-button:hover {
		background-color: #00b374;
		/* Darker shade on hover */
		color: #ddd;
		/* Lighter text color on hover */
	}

	.columns {
		width: 100%;
		height: 100vh;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		transform: scale(0.4);
	}

	.column {
		position: relative;
		display: grid;
	}

	.column-inner {
		position: relative;
		will-change: transform;
	}

	.column__item {
		margin: 0;
		position: relative;
		z-index: 1;
		width: var(--grid-item-width);
		height: var(--grid-item-height);
	}

	.column__item-imgwrap {
		width: 100%;
		height: 100%;
		position: relative;
		overflow: hidden;
	}

	/* Mobile first */
	.column__item-img {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: 50% 20%;
		backface-visibility: hidden;
	}

	/* Mobile first */
	.flip .column__item-img {
		background-size: auto 140%;
	}

	.content {
		pointer-events: none;
		position: fixed;
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
		display: grid;
		grid-template-rows: 1fr auto;
	}

	.content--current {
		pointer-events: auto;
	}

	.content__item {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.content__title {
		opacity: 0;
		text-transform: uppercase;
		align-self: center;
		margin: 0;
		position: relative;
		font-weight: 500;
		padding: 0 0.5rem;
		width: 100%;
		display: flex;
		pointer-events: none;
		justify-content: center;
		gap: 3vw;
		align-items: flex-start;
		color: var(--color-title);
		font-size: clamp(2rem, 13.85vw, 14rem);
		color: white;
		mix-blend-mode: difference;
	}

	.content__title span {
		display: block;
		/* line-height: 0.65; */
		padding-top: 0.075em;
	}

	.content__title>span:nth-child(2) {
		margin-top: 0.35em;
	}

	/* Mobile First */
	.content__nav {
		opacity: 0;
		width: 100%;
		position: fixed;
		bottom: 0;
		display: flex;
		gap: 0.5rem;
		/* padding: 1rem 1rem 6rem; */
		justify-content: center;
		overflow-x: auto;
		/* Enables horizontal scrolling */
		flex-wrap: wrap;
		/* Prevents items from wrapping */
	}

	/* Mobile First */
	.content__nav-item {
		cursor: pointer;
		width: 80px;
		border-radius: 5px;
		max-width: 90px;
		aspect-ratio: 1.3;
		background-size: cover;
		background-position: 50% 50%;
		place-items: center;
		font-size: 2rem;
		line-height: 0;
		font-weight: 400;
		display: none;
	}

	/* IMPORTANT Mobile first limited the number of nav grid item => -n+3 */
	/* .content__nav-item:nth-child(-n+3),
	.content__nav-item:last-child {
		display: grid;
	} */

	.content__nav-item:nth-child(-n+30),
	.content__nav-item:last-child {
		display: grid;
	}

	.content__nav-item--current {
		border: 2px solid var(--color-switch-bg);
	}

	.content__nav-item--more {
		aspect-ratio: unset;
		width: auto;
		padding: 0 0.5rem;
	}

	.switch {
		/*background: #a7bc8fde;*/
		z-index: 10;
		padding: 1.2rem 2rem 1rem;
		display: flex;
		position: fixed;
		gap: 1.5rem;
		left: 50%;
		transform: translate(-50%);
		align-items: center;
		/*border: 2px solid #000;*/
	}

	.switch__text {
		white-space: nowrap;
		line-height: 1;
		display: none;
	}

	.switch__button {
		display: grid;
		place-items: center;
		/* fill: var(--color-switch); */
		margin: 0;
		cursor: pointer;
		padding: 0.8rem;
		background-color: #007bff;
		border-radius: 5px;
		color: white;
	}

	.switch__button:hover {
		background-color: #00b374;
	}

	.switch__button--current {
		fill: var(--color-switch-active);
		pointer-events: none;
		cursor: default;
		background-color: #435e92;
	}

	.switch__button svg {
		pointer-events: none
	}


	.close--content__nav {
		background: none;
		border: none;
		cursor: pointer;
		position: absolute;
		top: 10px;
		/* Adjust this value to align it vertically */
		right: 10px;
		/* Adjust this value to align it horizontally */
		padding: 0;
		/* Adjust padding as needed */
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		transition: background-color 0.3s;
	}
}

/* Desktop Second */
@media screen and (min-width:53em) {
	.frame {
		align-content: space-between;
		height: 100%;
		grid-gap: 1rem;
		padding: 1.5rem;
		grid-template-columns: auto 1fr 1fr;
		grid-template-areas: 'heading ... sponsor' 'title prev ...';
	}

	.content__title {
		padding: 0;
	}

	.content__nav {
		gap: 1rem;
		padding: 1.5rem;
		justify-content: flex-end;
	}

	.content__nav-item {
		display: grid;
		width: 7vw;
		border-radius: 0.5vw;
	}

	/* Mobile first */
	.flip .column__item-img {
		background-size: auto 140%;
	}

	.switch {
		bottom: auto;
		top: 1.5rem;
	}

	.switch__text {
		display: block;
	}
}

/* Media query for mobile screens */
.desktop-only {
	display: none;
}

/* Media query for desktop screens */
@media screen and (min-width: 769px) {
	.desktop-only {
		display: block;
		/* or 'flex', 'inline-block', etc., depending on your layout */
	}
}