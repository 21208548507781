html,
body {
	height: 100%;
	overflow: hidden;
}

.desktop-mobile {

	*,
	*::after,
	*::before {
		box-sizing: border-box;
	}

	main {
		font-size: 14px;
		--color-text: hsl(300deg 33% 99%);
		--color-bg: hsl(120deg 3% 8%);
		--color-link: hsl(157deg 53% 61%);
		--color-link-hover: hsl(300deg 33% 99%);
		--title-gr-1: hsl(240deg 33% 99%);
		--title-gr-2: hsl(263deg 38% 53%);
		--color-desc: #ffffff;
	}

	main {
		margin: 0;
		color: var(--color-text);
		background-color: var(--color-bg);
		font-family: "vinila", -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-transform: uppercase;
	}

	.demo-2 {
		--title-gr-2: hsl(177deg 68% 51%);
	}

	.demo-3 {
		--title-gr-2: hsl(334deg 54% 44%);
	}


	@keyframes loaderAnim {
		to {
			opacity: 1;
			transform: scale3d(0.5, 0.5, 1);
		}
	}


	a {
		text-decoration: none;
		color: var(--color-link);
		outline: none;
		cursor: pointer;
	}

	a:hover {
		color: var(--color-link-hover);
		outline: none;
	}

	/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
	a:focus {
		/* Provide a fallback style for browsers
		 that don't support :focus-visible */
		outline: none;
		background: lightgrey;
	}

	a:focus:not(:focus-visible) {
		/* Remove the focus indicator on mouse-focus for browsers
		 that do support :focus-visible */
		background: transparent;
	}

	a:focus-visible {
		/* Draw a very noticeable focus style for
		 keyboard-focus on browsers that do support
		 :focus-visible */
		outline: 2px solid red;
		background: transparent;
	}

	.unbutton {
		background: none;
		border: 0;
		padding: 0;
		margin: 0;
		font: inherit;
		cursor: pointer;
	}

	.unbutton:focus {
		outline: none;
	}

	main {
		/* overflow-y: auto; */
		position: relative;
		display: grid;
		grid-template-areas: 'main';
		width: 100%;
		height: 100vh;
	}

	.oh {
		position: relative;
		overflow: hidden;
	}

	.oh__inner {
		will-change: transform;
		display: inline-block;
	}

	.frame {
		grid-area: main;
		z-index: 1000;
		position: relative;
		width: 100%;
		padding: 1.5rem;
		display: grid;
		grid-template-columns: 100%;
		grid-template-areas: 'title' 'prev' 'sponsor' 'demos';
		grid-gap: 0.5rem;
		justify-items: start;
		align-self: end;
		justify-self: start;
		pointer-events: none;
		align-items: center;

	}

	body #cdawrap {
		justify-self: start;
	}

	.frame a {
		pointer-events: auto;
	}

	.frame__title {
		grid-area: title;
		display: flex;
	}

	.frame__title-main {
		font-size: inherit;
		margin: 0;
		font-weight: inherit;
	}

	.frame__title-back {
		position: relative;
		display: flex;
		align-items: flex-end;
		margin-bottom: 0.15rem;
	}

	.frame__title-back span {
		display: none;
	}

	.frame__title-back svg {
		fill: currentColor;
	}

	.frame__prev {
		grid-area: prev;
	}

	.frame__demos {
		grid-area: demos;
		display: flex;
		gap: 1rem;
	}

	.intro-grid-wrap {
		grid-area: main;
		height: 100vh;
	}

	.intro-grid {
		grid-area: main;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		width: 100%;
		gap: 2vw;
		padding: 2vw 2vw 4rem;
	}

	.intro-grid--labels {
		padding-top: 0.75rem;
		grid-template-rows: auto;
		align-self: start;
		padding-bottom: 0;
		display: none;
	}

	.intro-grid--images {
		padding-top: 3rem;
		height: 100vh;
	}

	.intro-grid--slider {
		--slider-item-height: 60vh;
		--slider-item-gap: 7vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: calc(var(--slider-item-height) + var(--slider-item-gap));
		/* item height plus gap  */
		gap: 7vh;
		padding: 0;
		align-self: center;
	}

	.intro-grid--images.intro-grid--thumbs {
		padding-top: 1.85rem;
		padding-bottom: 0;
		grid-template-columns: repeat(20, 1fr);
		grid-template-rows: auto 5vh 1fr 10vh;
		column-gap: 1.5vw;
	}

	.intro-grid--thumbs .intro-grid__img {
		grid-area: auto;
		aspect-ratio: 0.75;
		cursor: not-allowed;
	}

	.intro-grid--thumbs .intro-grid__img--current {
		grid-area: 3 / 1 / 4 / -1;
		justify-self: center;
		pointer-events: none;
	}

	.intro-grid--slider .intro-grid__label {
		position: absolute;
	}

	.intro-grid__img {
		background-size: cover;
		background-position: 50% 20%;
		cursor: pointer;
		will-change: transform, filter;
		transform: translateZ(0.1px);
	}

	.intro-grid--slider .intro-grid__img {
		height: var(--slider-item-height);
		aspect-ratio: 0.75;
		flex: none;
	}

	.intro-grid--scatter {
		width: 200%;
		height: 200vh;
		padding: 0;
	}

	.intro-grid--scatter .intro-grid__img {
		max-width: 20vw;
		max-height: 50vh;
	}

	/* curremt img  */
	.intro-grid--scatter .intro-grid__img--current {
		width: 30vw;
		height: 70vh;
		max-width: none;
		max-height: none;
	}

	.intro-title {
		grid-area: main;
		align-self: center;
		justify-self: center;
		text-align: center;
		cursor: default;
		pointer-events: none;
	}

	.intro-title__main,
	.slider-title__main {
		margin: 0;
		font-size: clamp(1rem, 7vw, 10rem);
		font-family: organetto-variable, sans-serif;
		font-variation-settings: "wdth" 100, "wght" 400;
	}

	.intro-title__main>span,
	.slider-title__main>span {
		background-image: linear-gradient(45deg, var(--title-gr-1), var(--title-gr-2));
		background-size: 100%;
		background-repeat: repeat;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		-moz-background-clip: text;
		-moz-text-fill-color: transparent;
	}

	.intro-title__sub {
		font-family: microgramma-extended, sans-serif;
		font-weight: 500;
		font-style: normal;
		font-size: clamp(1rem, 4vw, 2rem);
		display: block;
	}

	.slider-title {
		opacity: 0;
		pointer-events: none;
		grid-area: main;
		align-self: center;
		justify-self: center;
		position: relative;
	}

	.slider-title__main {
		font-size: clamp(1rem, 6vw, 9rem);
	}

	.slider-title__desc {
		left: 10vw;
		position: absolute;
		margin: 0;
		color: var(--color-desc);
		max-width: 200px;
		background-color: #00000061;
		border-radius: 5%;
	}

	.buy-picture-button-mobile {
		position: fixed;
		z-index: 99;
		display: inline-block;
		/* Allows padding and width/height to be set */
		padding: 10px 20px;
		/* Button padding */
		background-color: #007bff;
		/* Background color */
		color: white;
		/* Text color */
		border: none;
		/* No border */
		border-radius: 5px;
		/* Rounded corners */
		cursor: pointer;
		/* Change mouse cursor on hover */
		text-align: center;
		/* Center text */
		transition: background-color 0.3s, color 0.3s;
		/* Smooth transition for hover effect */
		text-decoration: none;
		/* Remove underline if used within an <a> tag */

		/* Centering horizontally */
		left: 50%;
		transform: translateX(-50%);
	}

	.controls {
		/* position: absolute; */
		position: fixed;
		padding: 2rem;
		top: 0;
		right: 0;
		display: flex;
		text-transform: uppercase;
		color: var(--color-link);
		gap: 1rem;
		pointer-events: none;
		z-index: 100;
	}

	.controls--open {
		pointer-events: auto;
	}

	.controls button {
		text-transform: uppercase;
		color: var(--color-link);
	}

	.close {
		border: 1px solid var(--color-link);
		width: 3rem;
		height: 3rem;
		display: grid;
		place-items: center;
		border-radius: 50%;
		opacity: 0;
	}

	.nav {
		padding: 0 1.5rem;
		border: 1px solid var(--color-link);
		align-items: center;
		gap: 1rem;
		border-radius: 2rem;
		opacity: 0;
		display: none;
	}

	.nav__item {
		cursor: not-allowed;
	}

	@media screen and (min-width: 53em) {
		.frame {
			position: fixed;
			height: 100vh;
			grid-template-columns: auto auto auto 1fr;
			grid-template-rows: auto auto;
			grid-template-areas: '... ... ... ...' 'title prev sponsor demos';
			align-content: space-between;
			justify-items: start;
			grid-gap: 2rem;
			padding: 0.75rem;
		}

		.frame__demos {
			justify-self: end;
		}

		.intro-grid {
			grid-template-columns: repeat(10, 1fr);
		}

		.intro-grid--labels {
			display: grid;
		}

		.intro-grid--images {
			grid-template-rows: 1fr 1fr 1fr 1fr;
		}

		.intro-grid__img {
			background-position: 50% 50%;
		}

		.pos-1 {
			grid-area: 1 / 1;
		}

		.pos-2 {
			grid-area: 1 / 2;
		}

		.pos-3 {
			grid-area: 1 / 4;
		}

		.pos-4 {
			grid-area: 1 / 5;
		}

		.pos-5 {
			grid-area: 1 / 6;
		}

		.pos-6 {
			grid-area: 1 / 7;
		}

		.pos-7 {
			grid-area: 1 / 9;
		}

		.pos-8 {
			grid-area: 1 / 10;
		}

		.pos-9 {
			grid-area: 1 / 7;
		}

		.pos-10 {
			grid-area: 4 / 9;
		}

		.pos-11 {
			grid-area: 1 / 1;
		}

		.pos-12 {
			grid-area: 2 / 2;
		}

		.pos-13 {
			grid-area: 3 / 1;
		}

		.pos-14 {
			grid-area: 3 / 4;
		}

		.pos-15 {
			grid-area: 4 / 4;
		}

		.pos-16 {
			grid-area: 1 / 5;
		}

		.pos-17 {
			grid-area: 2 / 5;
		}

		.pos-18 {
			grid-area: 4 / 5;
		}

		.pos-19 {
			grid-area: 3 / 6;
		}

		.pos-20 {
			grid-area: 2 / 9;
		}

		.pos-21 {
			grid-area: 2 / 10;
		}

		.pos-22 {
			grid-area: 3 / 10;
		}

		.pos-23 {
			grid-area: 4 / 10;
		}

		.pos-24 {
			grid-area: 5 / 10;
		}

		.pos-25 {
			grid-area: 6 / 10;
		}

		.pos-26 {
			grid-area: 7 / 10;
		}

		.pos-27 {
			grid-area: 8 / 10;
		}

		.pos-28 {
			grid-area: 9 / 10;
		}

		.pos-29 {
			grid-area: 10 / 10;
		}

		.pos-30 {
			grid-area: 6 / 10;
		}

		.nav {
			display: flex;
		}
	}
}

/* Media query for mobile screens */
.desktop-mobile {
	display: block;
}

/* Media query for desktop screens */
@media screen and (min-width: 769px) {
	.desktop-mobile {
		display: none;
		/* or 'flex', 'inline-block', etc., depending on your layout */
	}
}