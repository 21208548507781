/* PaymentCard.css */
.PaymentCard-container {
    .format-selection {
        padding: 2rem 0;
    }

    .payment-card {
        max-width: 400px;
        margin: auto;
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        text-align: center;
    }

    .card-details {
        width: 90%; /* Default to mobile screen */
        margin: auto;
        text-align: left;
    }

    .card-details {
        label {
            font-weight: bold;
        }
    }
    /* Media query for desktop screens */
    @media (min-width: 768px) { /* Adjust the min-width as needed for your design */
        .card-details {
            width: 50%; /* Width for desktop screens */
        }
    }

    .payment-method {
        padding: 1rem;
    }

    /* Place the placeholder at the center of the input */
    input[name="cardNumber"],  
    input[name="cardExpiry"],
    input[name="cardCVC"] {
        text-align: center;
    }

    .payment-method label {
        margin-right: 20px;
        display: inline-flex;
        align-items: center;
    }

    .payment-method input[type="radio"] {
        margin-right: 5px;
    }

    .card-details input {
        display: block;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        box-sizing: border-box;
    }

    button {
        width: 100%;
        padding: 10px;
        background-color: blue;
        color: white;
        border: none;
        cursor: pointer;
    }
}