nav {
  margin-top: 10px;
  /* Adjust the margin as needed */
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  display: inline-block;
  margin-right: 10px;
  /* Adjust the margin as needed */
}

a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 16px;
  transition: color 0.3s ease;
  /* Add a smooth transition for color change */
}

a:hover {
  color: #555;
  /* Change the color on hover */
}

/* Add style for the active link */
a.active {
  border-bottom: 2px solid #333;
  /* Add underline for the active link */
}